.content {
    margin-top: 3%;
    margin-bottom: 2%;
}

.blogCardList {
    padding: 2%;
}

.pagination .ant-pagination-item-active {
    border-color: var(--red);
}


@media screen and (max-width: 720px) {
    .content {
        margin-top: 5%;
        margin-bottom: 9%;
        margin-left: 2%;
    }

    .pagination {
        margin-top: 15px;
    }

}