.content {
    margin-top: 3%;
}

.content input, .content select, .content textarea{
    box-shadow: none;
    border: solid 1px #eeeeee;
    border-radius: 2px;
    width: 100%;
}

.darkLink:hover {
    color: var(--red)
}

.darkButton {
    background-color: var(--red);
    color: #ffffff;
    border-color: var(--red);
    min-width: 100px;
    width: 100%;
}

.darkButton:hover {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    width: 100%;
}

.buttonLogin {
    background-color: var(--red);
    border-radius: 25px;
    border-color: var(--red);
    width: 100%;
}

@media screen and (max-width: 720px) {
    .content {
        margin-top: 2%;
        margin-left: 2%;
    }
    .content input, .content select, .content textarea{
        box-shadow: none;
        border: solid 1px #eeeeee;
        border-radius: 2px;
        width: 100%;
        margin-top: 5%;
    }
}