.content {
    margin-bottom: 5%;
}

.blogCard {
    width: 100%;
    padding-bottom: 2%;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(51, 51, 51, 0.15);
    background-color: #ffffff;
}

.blogCard:hover {
    box-shadow: 0 2px 8px rgba(51, 51, 51, 0.25);
}

.blogCardImage {
    object-fit: cover;
    width: 100%;
}

.blogCardDescription {
    text-align: center;
}

.blogCardTitle {
    text-align: center;
}

.blogCardTitle h1 {
    font-size: 140%;
}

.darkLink:hover {
    color: var(--red)
}

.darkButton {
    background-color: var(--red);
    color: #ffffff;
    border-color: var(--red);
    min-width: 100px;
    width: 30%;
}

.darkButton:hover {
    background-color: var(--red);
    border-color: var(--red);
    width: 30%;
}

.buttonLogin {
    background-color: var(--red);
    border-radius: 25px;
    border-color: var(--red);
    width: 30%;
}

