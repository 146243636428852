.title a {
    color: #333333;
}

.title a:hover {
    color: #333333;
}

.header {
    z-index: 100;
}

.navbar {
    width: 100%;
}

.transparent {
    background-color: rgba(255, 255, 255, 0);
}

.transparent li a {
    color: #FFFFFF;
    font-size: var(--medium-text-size);
}

.sticky {
    position: absolute;
    top: 0;
}

.sticky, .normalMenu {
    padding: 10px 0 5px 0;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.35)
}

.sticky li a, .normalMenu li a {
    color: #0d0d0d;
    font-size: var(--medium-text-size);
}


.logo {
    margin: 0px;
    width:100 px ;
}

.title {
    font-size: var(--medium-text-size);
    font-weight: bold;
    color: var(--dark-gray);
    display: inline-block;
    margin: 0px;
    padding: 0px ;
}

.itemCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: var(--grid-width);
}

.header ul {
    list-style: none;
    padding: 0;
}

.menuDesktop li {
    display: inline-block;
    padding: 0 10px 0 10px;
}

.header li a {
    text-decoration: none;
}

.active a,
.sticky .active a,
.transparent .active a,
.normalMenu .active a {
    color: var(--red);
}

.header a:hover {
    color: var(--red);
}

.burgerLogo {
    display: none;
}

.hidden {
    display: none;
}

@media screen and (max-width: 720px) {
    .smallHidden {
        display: none;
    }

    .container {
        width: 95%;
    }

    .burgerLogo {
        position: absolute;
        top: -4px;
        right: 0;
        display: inline-block;
    }

    .sticky .burgerLogo, .normalMenu .burgerLogo {
        top: 10px;
    }

    .burgerLogo button {
        background-color: white;
        outline: none;
    }

    .menuMobile {
        position: absolute;
        top: 54px;
        padding: 15px 0 15px 15px;
        width: 100%;
        text-align: center;
        box-shadow: 0 0 4px rgba(51, 51, 51, 0.25);
        z-index: 100;
    }

    .transparent .menuMobile {
        background-color: rgba(255, 255, 255, 0.8);
    }

    .transparent li a {
        color: var(--dark-blue);
    }

    .sticky .menuMobile, .normalMenu .menuMobile {
        background-color: #ffffff;
    }

    .menuMobile li {
        display: block;
    }

    .normalMenu {
        position: fixed;
    }
}
