.support {
    background-color: var(--dark-blue);
    color: #ffffff;
    padding: 25px 0 25px 0;
    font-size: var(--medium-text-size);
}

.footerLinks {
    text-align: center;
}

.footerLinks .container {
    border-bottom: solid 1px var(--light-blue);
    padding-bottom: 25px;
}

.footerLinks ul {
    list-style: none;
    padding: 0;
}

.footerLinks h3 {
    padding: 15px;
    margin-bottom: 15px;
    color: var(--red);
    font-weight: bold;
    border-bottom: solid 1px var(--light-blue);
}

.footerLinks strong {
    color: var(--red);
}

.footerLinks li {
    font-size: var(--medium-text-size);
}

.footerLinks a {
    text-decoration: none;
    color: var(--dark-blue);
}

.footerLinks a:hover {
    color: var(--red);
}

.copy {
    font-size: var(--medium-text-size);
    margin-top: 20px;
    padding: 10px;
    background-color: var(--dark-blue);
    color: #ffffff;
}



@media screen and (max-width: 720px) {
    .support {
        text-align: center;
        padding: 15px;
    }
}