.navBrew {
    font-size: 17px;
}

.navBrew a :hover {
    color: var(--red);
}

.navBrew a {
    text-decoration: none;
}

.welcome {
    background-color: var(--dark-blue);
    padding: 25px 0 25px 0;
    color: #ffffff;
}

.verticalCenter {
    display: flex;
    align-items: center;
}

.verticalCenter a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
}

.buttonMission {
    margin-top: 30%;
}

.welcome a {
    text-decoration: none;
}

.welcome button {
    border-radius: 5px;
    background-color: var(--red);
    text-decoration: none;
}

.welcome h3, .welcome span {
    color: #ffffff;
}

.postMission a {
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 720px) {
    .postMission {
        margin-top: 15px;
        justify-content: center;
        text-decoration: none;
    }
    .welcome {
        text-align: center;
    }
}
