.blogImage  {
    float: left;
    margin: 0 15px 0 0;
}

.content {
    margin-top: 3%;
    margin-bottom: 10%;
}

.blogCardDetails {
    color: var(--dark-blue);
}

@media screen and (max-width: 720px) {
    .content {
        margin-top: 5%;
        margin-bottom: 11%;
        margin-left: 2%;
    }

}