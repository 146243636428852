.blogCardDetails {
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
}

.like:hover {
    color: var(--red);
    cursor: pointer;
}


